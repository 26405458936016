import { Reducer } from '@reduxjs/toolkit';
import { HomeActions } from './actionTypes';
import { IHomeState, initialHomeState } from './state';
import * as types from './actionTypes';

export const homeReducer: Reducer<IHomeState, HomeActions> = (
  state: IHomeState = initialHomeState,
  action: HomeActions
): IHomeState => {
  switch (action.type) {
    case types.FetchHomeData: {
      return {
        ...state,
        isDataLoading: true,
      };
    }
    case types.FetchHomeDataSuccess: {
      return {
        ...state,
        data: action.payload,
        isDataLoading: false,
        dataLoadingError: undefined,
      };
    }
    case types.FetchHomeDataFailure: {
      return {
        ...state,
        data: undefined,
        isDataLoading: false,
        dataLoadingError: action.payload,
      };
    }
    default:
      return state;
  }
};
