import { ITheme, mergeStyles } from '@fluentui/react';

export const shadowDisplacement = '3px';
export const shadowSpread = '6px';

export const headerFooterStyles = (theme: ITheme): string =>
  mergeStyles({
    height: '50px',
    backgroundColor: theme.semanticColors.buttonBackgroundPressed,
  });

export const buttonStyles: string = mergeStyles({
  marginTop: '-32px !important',
  marginBottom: '32px',
});

export const imageStyles: string = mergeStyles({
  '.ms-Image-image': {
    width: '100%',
    maxWidth: '650px',
    margin: 'auto',
    marginTop: '-32px !important',
  },
});

export const getImageContainerStyles = (imageUrl: string): string =>
  mergeStyles({
    backgroundImage: `url('${imageUrl}')`,
  });
