import { IWall } from 'src/app/types';
import * as types from './actionTypes';

export const fetchHomeData = (): types.IFetchHomeDataAction => {
  const action: types.IFetchHomeDataAction = {
    type: types.FetchHomeData,
  };

  return action;
};

export const fetchHomeDataSuccess = (
  payload: IWall[]
): types.IFetchHomeDataSuccessAction => {
  const action: types.IFetchHomeDataSuccessAction = {
    type: types.FetchHomeDataSuccess,
    payload,
  };

  return action;
};

export const fetchHomeDataFailure = (
  error: Error
): types.IFetchHomeDataFailureAction => {
  const action: types.IFetchHomeDataFailureAction = {
    type: types.FetchHomeDataFailure,
    payload: error,
  };

  return action;
};
