import { ITheme, Stack, Text, Shimmer } from '@fluentui/react';
import { Component } from 'react';
import { getMetadataStyles } from './styles';
import './styles.scss';

export interface IWallCardShimmerProps {
  theme: ITheme;
}

export class WallCardShimmer extends Component<IWallCardShimmerProps> {
  render(): JSX.Element {
    return (
      <div className={`card shimmer-card`}>
        <div className='image-container'>
          <Shimmer height={200} />
        </div>
        <div className='heading mt-3'>
          <Text variant='mediumPlus'>
            <Shimmer width='100%' />
          </Text>
        </div>
        <div className='description mt-n-2'>
          <Shimmer width='90%' />
          <Shimmer width='80%' />
          <Shimmer width='100%' />
        </div>
        <div
          className={`${getMetadataStyles(
            this.props.theme
          )} metadata px-2 mt-2`}
        >
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Stack.Item>
              <Shimmer className='shimmer' width={50} />
            </Stack.Item>
            <Stack.Item>
              <Text variant='smallPlus'>&bull;</Text>
            </Stack.Item>
            <Stack.Item>
              <Shimmer className='shimmer' width={50} />
            </Stack.Item>
          </Stack>
        </div>
      </div>
    );
  }
}
