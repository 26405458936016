import { IResponse } from './types/response';
export interface ILoginState {
  data: IResponse | undefined;
  isDataLoading: boolean;
  dataLoadingError: Error | undefined;
}

// export const initialLoginState: ILoginState = {
//   data: undefined,
//   isDataLoading: false,
//   dataLoadingError: undefined,
// };

export const initialLoginState: ILoginState = {
  data: { results: { name: 'John', isLoggedIn: true } },
  isDataLoading: false,
  dataLoadingError: undefined,
};
