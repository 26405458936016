import {
  IconButton,
  ITheme,
  Stack,
  Text,
  Link,
  IContextualMenuProps,
  FontIcon,
} from '@fluentui/react';
import { Component } from 'react';
import { IWall } from 'src/app/types';
import { transitionTo } from 'src/utils';
import { getImageContainerStyles } from 'src/app/components/styles';
import { getActionStyles, getMetadataStyles } from './styles';
import './styles.scss';

export * from './shimmer';

export interface IWallCardProps {
  theme: ITheme;
  data: Partial<IWall>;
  onPreview: (wall: IWall) => void;
}

export interface IWallCardState {
  isBookmarked: boolean;
}

export class WallCard extends Component<IWallCardProps, IWallCardState> {
  constructor(props: IWallCardProps) {
    super(props);
    this.state = {
      isBookmarked: localStorage
        .getItem('bookmarkedWalls')
        ?.includes(`${this.props.data._id}`) as boolean,
    };
  }
  onRoute = (route: string): void => {
    transitionTo(route);
  };

  onBookmark = (): void => {
    const bookmarks = localStorage.getItem('bookmarkedWalls');
    if (!bookmarks?.includes(`${this.props.data._id}`)) {
      this.setState({ isBookmarked: true });
      localStorage.setItem(
        'bookmarkedWalls',
        `${bookmarks ? `${bookmarks},` : ''}${this.props.data._id}`
      );
    } else {
      this.setState({ isBookmarked: false });
      let bookmarkedArray = bookmarks.split(',');
      bookmarkedArray = bookmarkedArray.filter(
        (bookmark) => bookmark !== `${this.props.data._id}`
      );
      localStorage.setItem('bookmarkedWalls', bookmarkedArray.toString());
    }
  };

  render(): JSX.Element {
    const data = this.props.data as IWall;
    const menuProps: IContextualMenuProps = {
      items: [
        {
          key: '1',
          text: 'Preview',
          iconProps: { iconName: 'View' },
          onClick: () => {
            this.props.onPreview(data as IWall);
          },
        },
        {
          key: '2',
          text: `${this.state?.isBookmarked ? 'Remove bookmark' : 'Bookmark'}`,
          iconProps: {
            iconName: `${
              this.state?.isBookmarked ? 'SingleBookmark' : 'AddBookmark'
            }`,
          },
          onClick: this.onBookmark,
        },
      ],
    };

    return (
      <div className={`card`}>
        <div
          className={`${getImageContainerStyles(
            data.thumbNail
          )} image-container`}
          onClick={(e) => {
            e.preventDefault();
            this.onRoute(`/wall?v=${data._id}&i=0`);
          }}
        ></div>
        <Stack horizontal>
          <Stack.Item align='center' grow>
            <Link
              className='heading mt-2'
              title={data.title}
              onClick={(e) => {
                e.preventDefault();
                this.onRoute(`/wall?v=${data._id}&i=0`);
              }}
              href={`/wall?v=${data._id}&i=0`}
            >
              <strong>{data.title}</strong>
            </Link>
          </Stack.Item>
          <Stack.Item align='center'>
            <IconButton
              className={`${getActionStyles(this.props.theme)} footerButton`}
              iconProps={{ iconName: 'MoreVertical' }}
              title='More actions'
              ariaLabel='More actions'
              menuProps={menuProps}
            />
          </Stack.Item>
        </Stack>
        <div className='description mt-2'>
          <Text>{data.description}</Text>
        </div>
        <div className={`${getMetadataStyles(this.props.theme)} metadata px-2`}>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Stack.Item>
              <Text variant='smallPlus'>{`${data.totalLinks} Refs`}</Text>
            </Stack.Item>
            <Stack.Item>
              <Text variant='smallPlus'>&bull;</Text>
            </Stack.Item>
            <Stack.Item>
              <Text variant='smallPlus'>{`${data.hits} Views`}</Text>
            </Stack.Item>
            {/* <Stack.Item>
              <Text variant='smallPlus'>&bull;</Text>
            </Stack.Item>
            <Stack.Item>
              <Text variant='smallPlus'>{`${data.upVotes} Upvotes`}</Text>
            </Stack.Item> */}
          </Stack>
        </div>
        <FontIcon
          className={`bookmarkIndicator ${
            this.state.isBookmarked ? 'block' : 'hidden'
          }`}
          aria-label='Bookmarked'
          iconName='SingleBookmarkSolid'
        />
      </div>
    );
  }
}
