import { ITheme, mergeStyles } from '@fluentui/react';

export const logoStyles = (theme: ITheme): string =>
  mergeStyles({
    float: 'right',
    marginTop: '2px',
    cursor: 'pointer',
    '.ms-Image,img': {
      height: '28px',
    },
    '.ms-Image': {
      filter: theme.isInverted ? 'invert()' : '',
      float: 'left',
    },
    span: {
      float: 'left',
      marginTop: '2px',
      marginLeft: '4px',
    },
  });

export const iconStyles = (theme: ITheme): string =>
  mergeStyles({
    color: theme.semanticColors.bodyText,
    borderRadius: '100%',
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 'auto',
  });

export const addWallStyles: string = mergeStyles({
  margin: 'auto',
  '.ms-Button': {
    width: '36px',
    minWidth: 0,
    height: '36px',
    borderRadius: '999px',
  },
});
