import * as actionTypes from './actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IApiResponse } from './types/response';
import * as actions from './actions';
import { requestHelper } from 'src/utils';
import { getFormattedWalls } from './responseHelper';

export function* getWalls(action: actionTypes.IFetchHomeDataAction): Generator {
  try {
    const apiResponse = (yield call(requestHelper, 'walls', {
      params: {},
    })) as IApiResponse;
    const formattedWalls = getFormattedWalls(apiResponse.response);
    yield put(actions.fetchHomeDataSuccess(formattedWalls));
  } catch (error: any) {
    yield put(
      actions.fetchHomeDataFailure({
        name: 'getWalls Error',
        message: `${error.errorMessage}`,
      })
    );
  }
}

export function* homeSagas(): Generator {
  yield takeLatest(actionTypes.FetchHomeData, getWalls);
}
