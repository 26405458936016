import { IWall } from 'src/app/types';
import * as types from './actionTypes';

export const fetchBookmarksData = (): types.IFetchBookmarksDataAction => {
  const action: types.IFetchBookmarksDataAction = {
    type: types.FetchBookmarksData,
  };

  return action;
};

export const fetchBookmarksDataSuccess = (
  payload: IWall[]
): types.IFetchBookmarksDataSuccessAction => {
  const action: types.IFetchBookmarksDataSuccessAction = {
    type: types.FetchBookmarksDataSuccess,
    payload,
  };

  return action;
};

export const fetchBookmarksDataFailure = (
  error: Error
): types.IFetchBookmarksDataFailureAction => {
  const action: types.IFetchBookmarksDataFailureAction = {
    type: types.FetchBookmarksDataFailure,
    payload: error,
  };

  return action;
};
