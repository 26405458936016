import { history } from './history';

export const transitionTo = (path: string): void => {
  history.push(path);
};

export const baseUrl = (): string => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  } else {
    return 'https://tubewall.vercel.app/';
  }
};
