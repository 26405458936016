import { Stack, Text, Shimmer } from '@fluentui/react';
import { Component } from 'react';
import { LinkListShimmer } from '../common';
import './styles.scss';

export class WallShimmer extends Component<{}> {
  render(): JSX.Element {
    return (
      <div className='wall-shimmer'>
        <div className='ms-Grid' dir='ltr'>
          <div className='ms-Grid-row wall-container'>
            <div className='ms-Grid-col ms-sm12 ms-xl8 ms-xxxl9 px-0'>
              <Shimmer className='video' />
            </div>
            <div className='ms-Grid-col ms-sm12 ms-xl4 ms-xxxl3 wallList'>
              <div className='header pt-2'>
                <Text variant='mediumPlus'>
                  <Shimmer />
                </Text>
              </div>
              <div className='body mt-n-2'>
                <LinkListShimmer />
              </div>
            </div>
          </div>
        </div>
        <div className='ms-Grid-col ms-sm12 ms-xl8 ms-xxxl9 wall-detail'>
          <div className='mt-3 clamp-2 px-0'>
            <Text variant='large'>
              <Shimmer className='heading' width={'90%'} />
            </Text>
          </div>
          <div className='pt-2 clamp-2 px-0'>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal>
                <Stack.Item align='center'>
                  <Shimmer className='image' />
                </Stack.Item>
                <Stack.Item align='center' className='ml-2'>
                  <Shimmer width={'100px'} />
                </Stack.Item>
              </Stack>
            </Stack>
          </div>
          <div className='mt-3 description'>
            <div>
              <Text>
                <Shimmer />
              </Text>
              <Text className='ml-3'>
                <Shimmer />
              </Text>
            </div>
            <div>
              <Shimmer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
