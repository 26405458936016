import { Action } from '@reduxjs/toolkit';
import { IWall } from 'src/app/types';

export const FetchHomeData = 'FETCH_HOME_DATA';
export const FetchHomeDataSuccess = 'FETCH_HOME_DATA_SUCCESS';
export const FetchHomeDataFailure = 'FETCH_HOME_DATA_FAILURE';

export type IFetchHomeDataAction = Action<typeof FetchHomeData>;

export interface IFetchHomeDataSuccessAction
  extends Action<typeof FetchHomeDataSuccess> {
  payload: IWall[];
}

export interface IFetchHomeDataFailureAction
  extends Action<typeof FetchHomeDataFailure> {
  payload: Error;
}

export type HomeActions =
  | IFetchHomeDataAction
  | IFetchHomeDataSuccessAction
  | IFetchHomeDataFailureAction;
