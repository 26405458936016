import { IWall } from 'src/app/types';
import * as types from './actionTypes';

export const fetchWallData = (id: string): types.IFetchWallDataAction => {
  const action: types.IFetchWallDataAction = {
    type: types.FetchWallData,
    id,
  };

  return action;
};

export const fetchWallDataSuccess = (
  payload: IWall
): types.IFetchWallDataSuccessAction => {
  const action: types.IFetchWallDataSuccessAction = {
    type: types.FetchWallDataSuccess,
    payload,
  };

  return action;
};

export const fetchWallDataFailure = (
  error: Error
): types.IFetchWallDataFailureAction => {
  const action: types.IFetchWallDataFailureAction = {
    type: types.FetchWallDataFailure,
    payload: error,
  };

  return action;
};
