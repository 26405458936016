import { ITheme, mergeStyles } from '@fluentui/react';

export const getActionStyles = (
  theme: ITheme,
  shouldRotate: boolean = false
): string =>
  mergeStyles({
    borderRadius: '100%',
    zIndex: '1',
    transform: shouldRotate ? 'rotate(45deg)' : '',
    color: theme.palette.black,
    margin: '8px',
    WebkitBackdropFilter: 'blur(8px)',
    backdropFilter: 'blur(8px)',
    border: `1px solid ${theme.palette.black}`,
    background: !theme.isInverted
      ? 'rgba(255,255,255,0.8)'
      : 'rgba(34,34,34,0.8)',
  });

export const getMetadataStyles = (theme: ITheme): string =>
  mergeStyles({
    '*': {
      color: `${theme.semanticColors.buttonTextDisabled} !important`,
    },
  });

export const getBookmarkVisibility = (id: number): string =>
  mergeStyles({
    display: `${
      localStorage.getItem('bookmarkedWalls')?.includes(`${id}`)
        ? 'block'
        : 'none'
    } !important`,
  });
