import { IWall } from 'src/app/types';

export interface IBookmarksState {
  data: IWall[] | undefined;
  isDataLoading: boolean;
  dataLoadingError: Error | undefined;
}

export const initialBookmarksState: IBookmarksState = {
  data: undefined,
  isDataLoading: false,
  dataLoadingError: undefined,
};
