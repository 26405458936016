import {
  ITheme,
  Separator,
  Image,
  Text,
  IconButton,
  Stack,
  SearchBox,
  PrimaryButton,
  IContextualMenuProps,
  Panel,
  PanelType,
  Nav,
  INavLinkGroup,
  DefaultButton,
} from '@fluentui/react';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IRootState } from 'src/store';
import { history, ThemeModes, transitionTo } from 'src/utils';
import { updateTheme } from 'src/store/theme';
import { IHeaderMapState, IHeaderProps } from './header';
import { logoStyles } from './styles';
import { headerFooterStyles } from 'src/app/components/styles';
import logo from 'src/app/assets/img/logo.png';
import './styles.scss';

interface IHeaderState {
  path: string;
  isPanelOpen: boolean;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      path: history.location.pathname,
      isPanelOpen: false,
    };
  }
  navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: 'All',
          url: '/',
          key: 'key1',
          iconProps: { iconName: 'ViewAll2' },
          target: '_blank',
          onClick: (e) => {
            e?.preventDefault();
            this.OnDismissPanel();
            transitionTo('/');
          },
        },
        {
          name: 'Bookmarks',
          url: '/bookmarks',
          key: 'key2',
          iconProps: { iconName: 'SingleBookmarkSolid' },
          target: '_blank',
          onClick: (e) => {
            e?.preventDefault();
            this.OnDismissPanel();
            transitionTo('/bookmarks');
          },
        },
      ],
    },
  ];
  onLogoClick = (): void => {
    this.setState({ path: '/' });
    transitionTo('/');
  };
  OnDismissPanel = (): void => {
    this.setState({ isPanelOpen: false });
  };
  OnPanelOpen = (): void => {
    this.setState({ isPanelOpen: true });
  };
  isDarkMode = (theme: ITheme): boolean => {
    return theme.name === ThemeModes.Dark;
  };
  render(): JSX.Element {
    const theme = this.props.theme;
    const menuProps: IContextualMenuProps = {
      isBeakVisible: true,
      beakWidth: 12,
      items: [
        {
          key: '1',
          text: 'Login',
          iconProps: { iconName: 'AccountManagement' },
          onClick: () => {},
        },
        {
          key: '2',
          text: `Appearance: ${
            this.isDarkMode(this.props.theme) ? 'Dark' : 'Light'
          }`,
          iconProps: {
            iconName: `${
              this.isDarkMode(this.props.theme) ? 'ClearNight' : 'Sunny'
            }`,
          },
          subMenuProps: {
            items: [
              {
                key: '3',
                text: 'Light theme',
                iconProps: {
                  iconName: `${
                    this.isDarkMode(this.props.theme) ? '' : 'CheckMark'
                  }`,
                },
                onClick: this.props.updateTheme,
              },
              {
                key: '4',
                text: 'Dark theme',
                iconProps: {
                  iconName: `${
                    this.isDarkMode(this.props.theme) ? 'CheckMark' : ''
                  }`,
                },
                onClick: this.props.updateTheme,
              },
            ],
          },
        },
      ],
    };

    return (
      <>
        <Panel
          isLightDismiss
          isOpen={this.state.isPanelOpen}
          onDismiss={this.OnDismissPanel}
          type={PanelType.smallFixedNear}
          closeButtonAriaLabel='Close'
          headerText='Tubewall'
          className='header-panel'
        >
          <Nav groups={this.navLinkGroups} />
          <Separator />
          <DefaultButton
            iconProps={{ iconName: 'Fingerprint' }}
            className='w-100 t-left'
            text='Log In'
            allowDisabledFocus
          />
          <Separator />
        </Panel>
        <div
          className={`${headerFooterStyles(theme)} header-top header-sticky`}
        >
          <Stack horizontal horizontalAlign='space-between' className='h-100'>
            <Stack.Item align='center' className='logo'>
              <IconButton
                iconProps={{
                  iconName: 'CollapseMenu',
                }}
                title='Menu'
                ariaLabel='Menu'
                className='headerButtons menu'
                onClick={() => {
                  this.OnPanelOpen();
                }}
              />
              <div
                className={logoStyles(theme)}
                onClick={() => this.onLogoClick()}
              >
                <Image loading='lazy' src={logo} />
                <Text variant='mediumPlus' className='tubewall'>
                  <strong>{'Tubewall'}</strong>
                </Text>
              </div>
            </Stack.Item>
            <Stack.Item align='center' grow className='search-container ml-2'>
              <SearchBox
                placeholder='Search'
                onSearch={(newValue) => console.log('value is ' + newValue)}
              />
            </Stack.Item>
            <Stack.Item align='center'>
              <PrimaryButton
                className='headerButtons primary'
                iconProps={{ iconName: 'AppIconDefaultAdd' }}
              />
              <IconButton
                iconProps={{
                  iconName: 'Contact',
                }}
                title='Account'
                ariaLabel='Account'
                className='headerButtons account'
                menuProps={menuProps}
              />
            </Stack.Item>
          </Stack>
        </div>
        <Separator className='header-sticky-sep' />
      </>
    );
  }
}

export const mapStateToProps = (state: IRootState): IHeaderMapState => {
  return {
    theme: state.theme,
  };
};

const mapDispatchToProps = {
  updateTheme,
};

export default compose<React.ComponentType>(
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
