import { IWall } from 'src/app/types';

export interface IHomeState {
  data: IWall[] | undefined;
  isDataLoading: boolean;
  dataLoadingError: Error | undefined;
}

export const initialHomeState: IHomeState = {
  data: undefined,
  isDataLoading: false,
  dataLoadingError: undefined,
};
