import { Route, Switch } from 'react-router-dom';
import Home from './components/home';
import { ThemeProvider } from '@fluentui/react';
import Header from './components/header';
import Footer from './components/footer';
import { GetCurrentTheme } from 'src/utils';
import { Helmet } from 'react-helmet';
import Bookmarks from './components/bookmarks';
import Wall from './components/wall';

function Main() {
  const themeMode = GetCurrentTheme();

  return (
    <ThemeProvider theme={themeMode}>
      <Helmet defaultTitle='Tubewall' titleTemplate='%s - Tubewall'></Helmet>
      <Header />
      <Switch>
        {/* <Route
          path={'/bookmarks'}
          render={(): JSX.Element => {
            return (
              <AuthenticatedRoute
                component={<Bookmarks />}
                path='bookmarks'
              ></AuthenticatedRoute>
            );
          }}
        /> */}
        <Route
          path={'/bookmarks'}
          render={(): JSX.Element => {
            return <Bookmarks />;
          }}
        />
        <Route
          path={'/wall'}
          render={(): JSX.Element => {
            return <Wall />;
          }}
        />
        {/* <Route
          path={'/login'}
          render={(): JSX.Element => {
            return <Login />;
          }}
        /> */}
        <Route
          path={'/'}
          render={(): JSX.Element => {
            return <Home />;
          }}
        />
      </Switch>
      <Footer />
    </ThemeProvider>
  );
}

export default Main;
