import { Stack, Separator, Image, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { IRootState } from 'src/store';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  Contribute,
  LinkList,
  WallCard,
  WallCardShimmer,
} from 'src/app/components/common';
import { imageStyles } from 'src/app/components/styles';
import start from 'src/app/assets/img/start.svg';

import { Helmet } from 'react-helmet';
import { IBookmarksMapState, IBookmarksProps } from './store/types/bookmarks';
import { fetchBookmarksData } from './store/actions';
import { injectReducer, injectSaga } from 'redux-injectors';
import { bookmarksSagas } from './store/sagas';
import { bookmarksReducer } from './store/reducer';
import { ILink, IWall } from 'src/app/types';

export interface IBookmarksState {
  isPanelOpen: boolean;
  selectedWall: Partial<IWall>;
}

export class Bookmarks extends React.Component<
  IBookmarksProps,
  IBookmarksState
> {
  constructor(props: IBookmarksProps) {
    super(props);
    this.state = { isPanelOpen: false, selectedWall: {} };
  }

  componentDidMount(): void {
    this.props.fetchBookmarksData();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  OnPreview = (wall: IWall): void => {
    this.setState({ isPanelOpen: true, selectedWall: wall });
  };

  OnDismissPanel = (): void => {
    this.setState({ isPanelOpen: false });
  };

  getShimmer = (): JSX.Element => {
    return (
      <>
        {[...Array(18)].map((_, index) => {
          return (
            <div
              className='ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxxl2'
              key={index}
            >
              <WallCardShimmer theme={this.props.theme} />
            </div>
          );
        })}
      </>
    );
  };

  getWalls = (): JSX.Element => {
    return (
      <>
        {this.props.bookmarks.data?.map((wall, index) => {
          return (
            <div
              className='ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxxl2'
              key={index}
            >
              <WallCard
                theme={this.props.theme}
                data={wall}
                onPreview={this.OnPreview}
              ></WallCard>
            </div>
          );
        })}
      </>
    );
  };

  getEmptyState = (): JSX.Element => {
    return (
      <>
        <Image className={imageStyles} loading='lazy' src={start} />
        <Stack style={{ marginTop: '-64px' }}>
          <Stack.Item align='center'>
            <h2>{'There is nothing here yet!'}</h2>
          </Stack.Item>
        </Stack>
      </>
    );
  };
  render(): JSX.Element {
    return (
      <>
        <Helmet>
          <title>{'Home'}</title>
        </Helmet>
        <Panel
          isLightDismiss
          isOpen={this.state.isPanelOpen}
          onDismiss={this.OnDismissPanel}
          type={PanelType.medium}
          closeButtonAriaLabel='Close'
          headerText={this.state.selectedWall.title}
          className='panelContent'
        >
          <LinkList
            wallId={this.state.selectedWall._id as number}
            data={this.state.selectedWall.links as ILink[]}
          />
        </Panel>
        <Stack className='container'>
          <div className='ms-Grid px-0' dir='ltr'>
            <div className='ms-Grid-row'>
              {this.props.bookmarks.isDataLoading
                ? this.getShimmer()
                : this.props.bookmarks.dataLoadingError
                ? this.getEmptyState()
                : this.getWalls()}
            </div>
          </div>
          <Separator />
          <Contribute />
        </Stack>
      </>
    );
  }
}

export const mapStateToProps = (state: IRootState): IBookmarksMapState => {
  return {
    theme: state.theme,
    bookmarks: state.bookmarks,
  };
};

const mapDispatchToProps = {
  fetchBookmarksData,
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'bookmarks',
    reducer: bookmarksReducer,
  }),
  injectSaga({ key: 'bookmarks', saga: bookmarksSagas }),
  connect(mapStateToProps, mapDispatchToProps)
)(Bookmarks);
