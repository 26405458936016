import { Reducer } from '@reduxjs/toolkit';
import { BookmarksActions } from './actionTypes';
import { IBookmarksState, initialBookmarksState } from './state';
import * as types from './actionTypes';

export const bookmarksReducer: Reducer<IBookmarksState, BookmarksActions> = (
  state: IBookmarksState = initialBookmarksState,
  action: BookmarksActions
): IBookmarksState => {
  switch (action.type) {
    case types.FetchBookmarksData: {
      return {
        ...state,
        isDataLoading: true,
      };
    }
    case types.FetchBookmarksDataSuccess: {
      return {
        ...state,
        data: action.payload,
        isDataLoading: false,
        dataLoadingError: undefined,
      };
    }
    case types.FetchBookmarksDataFailure: {
      return {
        ...state,
        data: undefined,
        isDataLoading: false,
        dataLoadingError: action.payload,
      };
    }
    default:
      return state;
  }
};
