import { Stack, Link } from '@fluentui/react';
import { Component } from 'react';
import { ILink } from 'src/app/types';
import { getImageContainerStyles } from 'src/app/components/styles';
import { transitionTo } from 'src/utils';
import './styles.scss';

export interface ILinkListProps {
  data: Partial<ILink[]>;
  wallId: number;
  selectedIndex?: string;
  onSelect?: (index: number) => void;
}

export class LinkList extends Component<ILinkListProps> {
  onRoute = (route: string): void => {
    transitionTo(route);
  };

  render(): JSX.Element {
    const data = this.props.data as ILink[];
    return (
      <>
        {data?.map((link, index) => {
          return (
            <Stack
              horizontal
              key={index}
              className={`link-list mt-3 ${
                parseInt(this.props.selectedIndex as string) === index
                  ? 'selected'
                  : ''
              }`}
            >
              <Stack.Item>
                <div
                  className={`${getImageContainerStyles(
                    link.thumbnail as string
                  )} image-container`}
                  onClick={() => {
                    this.onRoute(`/wall?v=${this.props.wallId}&i=${index}`);
                    this.props.onSelect?.(index);
                  }}
                />
              </Stack.Item>
              <Stack.Item align='center' className='ml-2'>
                <Stack>
                  <Stack.Item className='creator'>
                    <Link
                      href={link.creatorUrl}
                      target='_blank'
                      className='text-muted'
                    >
                      {link.creator}
                    </Link>
                  </Stack.Item>
                  <Stack.Item>
                    <Link
                      className='heading pr-1'
                      title={link.title}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRoute(`/wall?v=${this.props.wallId}&i=${index}`);
                        this.props.onSelect?.(index);
                      }}
                      href={`/wall?v=${this.props.wallId}&i=${index}`}
                    >
                      <strong>{link.title}</strong>
                    </Link>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          );
        })}
      </>
    );
  }
}
