import { Action } from '@reduxjs/toolkit';
import { IWall } from 'src/app/types';

export const FetchBookmarksData = 'FETCH_BOOKMARKS_DATA';
export const FetchBookmarksDataSuccess = 'FETCH_BOOKMARKS_DATA_SUCCESS';
export const FetchBookmarksDataFailure = 'FETCH_BOOKMARKS_DATA_FAILURE';

export type IFetchBookmarksDataAction = Action<typeof FetchBookmarksData>;

export interface IFetchBookmarksDataSuccessAction
  extends Action<typeof FetchBookmarksDataSuccess> {
  payload: IWall[];
}

export interface IFetchBookmarksDataFailureAction
  extends Action<typeof FetchBookmarksDataFailure> {
  payload: Error;
}

export type BookmarksActions =
  | IFetchBookmarksDataAction
  | IFetchBookmarksDataSuccessAction
  | IFetchBookmarksDataFailureAction;
