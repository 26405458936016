import { Stack, Separator, Panel, PanelType, Text } from '@fluentui/react';
import React from 'react';
import { IRootState } from 'src/store';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  Contribute,
  LinkList,
  WallCard,
  WallCardShimmer,
} from 'src/app/components/common';
import { Helmet } from 'react-helmet';
import { IHomeMapState, IHomeProps } from './store/types/home';
import { injectReducer, injectSaga } from 'redux-injectors';
import { fetchHomeData } from './store/actions';
import { homeReducer } from './store/reducer';
import { homeSagas } from './store/sagas';
import { ILink, IWall } from 'src/app/types';

export interface IHomeState {
  isPanelOpen: boolean;
  selectedWall: Partial<IWall>;
}

export class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);
    this.state = { isPanelOpen: false, selectedWall: {} };
  }

  componentDidMount(): void {
    this.props.fetchHomeData();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  OnDismissPanel = (): void => {
    this.setState({ isPanelOpen: false });
  };

  OnPreview = (wall: IWall): void => {
    this.setState({ isPanelOpen: true, selectedWall: wall });
  };

  getShimmer = (): JSX.Element => {
    return (
      <>
        {[...Array(18)].map((_, index) => {
          return (
            <div
              className='ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxxl2'
              key={index}
            >
              <WallCardShimmer theme={this.props.theme} />
            </div>
          );
        })}
      </>
    );
  };

  getWalls = (): JSX.Element => {
    return (
      <>
        {this.props.home.data?.map((wall, index) => {
          return (
            <div
              className='ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxxl2'
              key={index}
            >
              <WallCard
                theme={this.props.theme}
                data={wall}
                onPreview={this.OnPreview}
              ></WallCard>
            </div>
          );
        })}
      </>
    );
  };

  render(): JSX.Element {
    return (
      <>
        <Helmet>
          <title>{'Home'}</title>
        </Helmet>
        <Panel
          isLightDismiss
          isOpen={this.state.isPanelOpen}
          onDismiss={this.OnDismissPanel}
          type={PanelType.medium}
          closeButtonAriaLabel='Close'
          headerText={this.state.selectedWall.title}
          className='panelContent'
        >
          <Text className='clamp-5'>{this.state.selectedWall.description}</Text>
          <LinkList
            wallId={this.state.selectedWall._id as number}
            data={this.state.selectedWall.links as ILink[]}
          />
        </Panel>
        <Stack className='container'>
          <div className='ms-Grid px-0' dir='ltr'>
            <div className='ms-Grid-row'>
              {this.props.home.isDataLoading
                ? this.getShimmer()
                : this.getWalls()}
            </div>
          </div>
          <Separator />
          <Contribute />
        </Stack>
      </>
    );
  }
}

export const mapStateToProps = (state: IRootState): IHomeMapState => {
  return {
    theme: state.theme,
    home: state.home,
  };
};

const mapDispatchToProps = {
  fetchHomeData,
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'home',
    reducer: homeReducer,
  }),
  injectSaga({ key: 'home', saga: homeSagas }),
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
