import { ITheme } from '@fluentui/react';
import { defaultTheme } from 'src/utils';
import {
  IHomeState,
  initialHomeState,
} from 'src/app/components/home/store/state';
import {
  IBookmarksState,
  initialBookmarksState,
} from 'src/app/components/bookmarks/store/state';
import {
  IWallState,
  initialWallState,
} from 'src/app/components/wall/store/state';
import {
  ILoginState,
  initialLoginState,
} from 'src/app/components/login/store/state';

export type RootStateKey = keyof IRootState;

// Tip: Declare your reducers here
export interface IRootState {
  theme: ITheme;
  login: ILoginState;
  home: IHomeState;
  bookmarks: IBookmarksState;
  wall: IWallState;
}

export const initialRootState: IRootState = {
  theme: defaultTheme,
  login: initialLoginState,
  home: initialHomeState,
  bookmarks: initialBookmarksState,
  wall: initialWallState,
};
