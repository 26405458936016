import { Reducer } from '@reduxjs/toolkit';
import { WallActions } from './actionTypes';
import { IWallState, initialWallState } from './state';
import * as types from './actionTypes';

export const wallReducer: Reducer<IWallState, WallActions> = (
  state: IWallState = initialWallState,
  action: WallActions
): IWallState => {
  switch (action.type) {
    case types.FetchWallData: {
      return {
        ...state,
        isDataLoading: true,
      };
    }
    case types.FetchWallDataSuccess: {
      return {
        ...state,
        data: action.payload,
        isDataLoading: false,
        dataLoadingError: undefined,
      };
    }
    case types.FetchWallDataFailure: {
      return {
        ...state,
        data: undefined,
        isDataLoading: false,
        dataLoadingError: action.payload,
      };
    }
    default:
      return state;
  }
};
