import * as actionTypes from './actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IApiResponse } from './types/response';
import * as actions from './actions';
import { requestHelper } from 'src/utils';
import { getFormattedWalls } from './responseHelper';

export function* getWalls(
  action: actionTypes.IFetchBookmarksDataAction
): Generator {
  try {
    const apiResponse = (yield call(requestHelper, 'bookmarkedWalls', {
      params: {},
      body: { bookmarkedWalls: localStorage.getItem('bookmarkedWalls') },
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    })) as IApiResponse;
    const formattedWalls = getFormattedWalls(apiResponse.response);
    yield put(actions.fetchBookmarksDataSuccess(formattedWalls));
  } catch (error: any) {
    yield put(
      actions.fetchBookmarksDataFailure({
        name: 'getWalls Error',
        message: `${error.errorMessage}`,
      })
    );
  }
}

export function* bookmarksSagas(): Generator {
  yield takeLatest(actionTypes.FetchBookmarksData, getWalls);
}
