import {
  FontIcon,
  ITheme,
  Link,
  mergeStyles,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { compose } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { headerFooterStyles } from 'src/app/components/styles';
import { IRootState } from 'src/store';

const iconStyles = mergeStyles({
  color: 'var(--black)',
  fontSize: 10,
});

export interface IFooterProps {
  theme: ITheme;
}

export class Footer extends React.Component<IFooterProps> {
  render(): JSX.Element {
    const theme = this.props.theme;
    return (
      <>
        <Separator />
        <div className={headerFooterStyles(theme)}>
          <Stack
            className='container'
            verticalAlign='center'
            style={{ height: '100%' }}
          >
            <Stack.Item align='center'>
              <Text>
                {'Designed, Developed & Maintained by '}
                <Link
                  href='https://irizwan.com'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <strong>{'Rizwan'}</strong>
                  <FontIcon
                    iconName='OpenInNewTab'
                    className={`${iconStyles} ml-1`}
                  />
                </Link>
              </Text>
            </Stack.Item>
          </Stack>
        </div>
      </>
    );
  }
}

export const mapStateToProps = (state: IRootState): IFooterProps => {
  return {
    theme: state.theme,
  };
};

export default compose<React.ComponentType>(connect(mapStateToProps))(Footer);
