import { Stack, Shimmer } from '@fluentui/react';
import { Component } from 'react';
import './styles.scss';

export class LinkListShimmer extends Component<{}> {
  render(): JSX.Element {
    return (
      <div className='link-list-shimmer mt-5'>
        {[...Array(18)].map((link, index) => {
          return (
            <Stack horizontal key={index} className='link-list mt-3'>
              <Stack.Item>
                <Shimmer className='image-container' />
              </Stack.Item>
              <Stack.Item align='center' className='ml-2 w-100'>
                <Stack>
                  <Stack.Item className='creator'>
                    <Shimmer width={`${28 + Math.random() * 8}%`} />
                  </Stack.Item>
                  <Stack.Item>
                    <Shimmer width={`${80 + Math.random() * 10}%`} />
                    <Shimmer
                      width={`${70 + Math.random() * 10}%`}
                      className='mt-1'
                    />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          );
        })}
      </div>
    );
  }
}
