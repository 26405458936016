import { Stack, Separator, Text, Link, DefaultButton } from '@fluentui/react';
import React from 'react';
import { IRootState } from 'src/store';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { Contribute, LinkList } from 'src/app/components/common';
import { Helmet } from 'react-helmet';
import { iframeStyles } from './styles';
import './styles.scss';
import { ProviderIconMapping } from 'src/utils';
import { IWallMapState, IWallProps } from './store/types/wall';
import { fetchWallData } from './store/actions';
import { injectReducer, injectSaga } from 'redux-injectors';
import { wallReducer } from './store/reducer';
import { wallSagas } from './store/sagas';
import { AnyStateAnyAction, IWall } from 'src/app/types';
import { WallShimmer } from './shimmer';

export interface IWallState {
  selectedIndex: number;
}

export class Wall extends React.Component<IWallProps, IWallState> {
  constructor(props: IWallProps) {
    super(props);
    this.state = {
      selectedIndex: this.searchParams.get('i') as unknown as number,
    };
  }

  componentDidMount(): void {
    this.props.fetchWallData(this.searchParams.get('v') as string);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  searchParams = new URLSearchParams(window.location.search);

  onListSelect = (index: number): void => {
    this.setState({ selectedIndex: index });
  };

  getVideoId = (url: string): string => {
    const youtubeTemplateUrl = 'https://www.youtube.com/embed';
    const youtubeId = url.split('?v=')[1];

    return `${youtubeTemplateUrl}/${youtubeId}`;
  };

  getWallShimmer = (): JSX.Element => {
    return <WallShimmer />;
  };

  getWallContent = (data: IWall): JSX.Element => {
    return (
      <>
        <div className='ms-Grid' dir='ltr'>
          <div className='ms-Grid-row wall-container'>
            <div className='ms-Grid-col ms-sm12 ms-xl8 ms-xxxl9 px-0'>
              <iframe
                className={iframeStyles}
                src={this.getVideoId(data.links[this.state.selectedIndex].url)}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
            <div className='ms-Grid-col ms-sm12 ms-xl4 ms-xxxl3 wallList'>
              <div className='header pt-2'>
                <Text variant='mediumPlus'>
                  <strong>{data.title}</strong>
                </Text>
              </div>
              <div className='body mt-n-2'>
                <LinkList
                  onSelect={this.onListSelect}
                  selectedIndex={this.state.selectedIndex as unknown as string}
                  wallId={this.searchParams.get('v') as unknown as number}
                  data={data.links}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='ms-Grid-col ms-sm12 ms-xl8 ms-xxxl9 wall-detail'>
          <div
            className='mt-3 clamp-2 px-0'
            title={data.links[this.state.selectedIndex].title}
          >
            <Text variant='large'>
              <strong>{data.links[this.state.selectedIndex].title}</strong>
            </Text>
          </div>
          <div className='pt-2 px-0'>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal className='mw-60'>
                <Stack.Item align='center'>
                  <img
                    className='provider-icon'
                    src={ProviderIconMapping.YouTube}
                    alt='YouTube'
                  />
                </Stack.Item>
                <Stack.Item align='center' className='ml-2 clamp-2'>
                  <Link
                    className='creator mt-2'
                    href={data.links[this.state.selectedIndex].creatorUrl}
                    target='_blank'
                  >
                    <strong>
                      {data.links[this.state.selectedIndex].creator}
                    </strong>
                  </Link>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item align='center' className='votes'>
                  <DefaultButton
                    iconProps={{ iconName: 'Like' }}
                    text={`${data.upVotes}`}
                    onClick={() => {}}
                  />
                  <DefaultButton
                    iconProps={{ iconName: 'DisLike' }}
                    onClick={() => {}}
                  />
                </Stack.Item>
                <Stack.Item className='share ml-2'>
                  <DefaultButton
                    iconProps={{ iconName: 'Share' }}
                    text='Share'
                    onClick={() => {}}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
          </div>
          <div className='mt-3 description'>
            <div>
              <Text>
                <strong>{`${data.hits + 1} Views`}</strong>
              </Text>
              <Text className='ml-3'>
                <strong>{'4 Years ago'}</strong>
              </Text>
            </div>
            <div>
              <Text>{data.description}</Text>
            </div>
          </div>
        </div>
      </>
    );
  };

  render(): JSX.Element {
    return (
      <>
        <Helmet>
          <title>{'Wall'}</title>
        </Helmet>
        <Stack className='container wall'>
          {this.props.wall.isDataLoading
            ? this.getWallShimmer()
            : this.props.wall.data
            ? this.getWallContent(this.props.wall.data)
            : 'error'}
          <Separator />
          <Contribute />
        </Stack>
      </>
    );
  }
}

export const mapStateToProps = (state: IRootState): IWallMapState => {
  return {
    wall: state.wall,
  };
};

const mapDispatchToProps = {
  fetchWallData,
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'wall',
    reducer: wallReducer as AnyStateAnyAction,
  }),
  injectSaga({ key: 'wall', saga: wallSagas }),
  connect(mapStateToProps, mapDispatchToProps)
)(Wall);
