import { Action } from '@reduxjs/toolkit';
import { IWall } from 'src/app/types';

export const FetchWallData = 'FETCH_WALL_DATA';
export const FetchWallDataSuccess = 'FETCH_WALL_DATA_SUCCESS';
export const FetchWallDataFailure = 'FETCH_WALL_DATA_FAILURE';

export interface IFetchWallDataAction extends Action<typeof FetchWallData> {
  id: string;
}

export interface IFetchWallDataSuccessAction
  extends Action<typeof FetchWallDataSuccess> {
  payload: IWall;
}

export interface IFetchWallDataFailureAction
  extends Action<typeof FetchWallDataFailure> {
  payload: Error;
}

export type WallActions =
  | IFetchWallDataAction
  | IFetchWallDataSuccessAction
  | IFetchWallDataFailureAction;
