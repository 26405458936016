import { IWall } from 'src/app/types';

export const getFormattedWalls = (data: IWall[]): IWall[] => {
  let walls: IWall[] = [];
  data.forEach((wall) => {
    let tmpWall: IWall = { ...wall };
    tmpWall.thumbNail = tmpWall.links[0].thumbnail;
    walls.push(tmpWall);
  });
  return walls;
};
